import React from 'react'

function About(){
    return(
        <div>
            <div class ="title">Get to know me more</div>
            <div class = "main_about">
                <div class="boxes_about">
                    <div class="questions">
                        How did I get into programming?
                    </div>
                    <div class="answers">
                        I always liked solving problems. One of my lecturers that thought me algorithms and cloud computing
                        suggested I take a look at Deep Learning. I wasn't too be into programming back then as I didn't 
                        understand the whole picture. As soon as I've ventured into the unknown, I was compelled by what can
                        really be done through code. 
                        <br/>
                        I have realized that programming is like Lego. I've played with Lego all my life. You're given pieces
                        and can build whatever you want. Some pieces can't fit with each other but there are workarounds.
                    </div>
                </div>
                <div class="boxes_about">
                    <div class="questions">
                        One thing I regret and why?
                    </div>
                    <div class="answers">
                        One thing I really regret is not starting programming early. I always thought that you need to be in college
                        or university to become a programmer. As soon as I went into college I was proven otherwise. This made me
                        realise that there are some invisible barriers people have. Those barriers can be broken with research, ambition
                        and determination.
                    </div>
                </div>
                <div class="boxes_about">
                    <div class="questions">
                        What do I consider to be your greatest strength and weakness?
                    </div>
                    <div class="answers">
                        Going into a task with the unknown. It's weakness is not knowing what to do right away. It's 
                        strength is having a fresh view on the task which can provide new and fresh ideas.
                        You also get to learn new skills and improve your ability to adapt.
                    </div>
                </div>
                <div class="boxes_about">
                    <div class="questions">
                        Why do I like what I'm doing?
                    </div>
                    <div class="answers">
                        Being able to overcome obstacles in projects is really rewarding by itself. Having so much 
                        variety in learning and knowing that you will never know all provides a slight thrill when choosing
                        what to pursue next. Being able to have all tools for producing exciting product at your finger tips
                        makes it that more interesting. Imagination is your only barrier. (sometimes hardware too)
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About