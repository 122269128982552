import React from 'react'
import sample from './img/sample_project.jpg'
import slam from './img/slamimg.png'
import rlearn from './img/rlearn.png'
import weather from './img/weather.png'
import tinderbot from './img/ai_tinder_bot_project.png'
import car_fyp from './img/fyp.png'
import ip_ca1 from './img/image_processing_ca_1.png'
import ip_ca2 from './img/image_processing_ca_2.png'

export default function Projects() {
    return (
        <div>

            <div class ="title">Check out some of my projects</div>
            <div class="project_body">


                <div class = "project_container">
                    <img src={car_fyp} alt="sample" className="project_pic"/>
                    <div class="project_title">Scaled Down Autonomous Car</div>
                    <div class="project_paragraph">
                        This is my final year project which won first place at a project fair. 
                        The project uses CNN in order to predict turn input for an RC car. 
                        A track is made which allows for data gathering consisting of an image and current turn input for that image. 
                        Data is prepared and used for training of a model which then is used for predicting of turn in order to perform laps of track autonomously.
                        This projected consisted of many unexpected challenges which had to be overcome which made the project more fun.
                    </div>
                </div>


                <div class = "project_container">
                    <img src={ip_ca1} alt="sample" className="project_pic"/>
                    <div class="project_title">Frame Fade Photo Restorer</div>
                    <div class="project_paragraph">
                        Program that allows for restoration of frame faded images. 
                        To do this it uses canny edge detection to find the faded region. 
                        After, it goes along the faded and non faded sides of the image to find the mean difference of V value in HSV color.
                        Once mean difference value of V gets calculated, it is added to the faded region.
                    </div>
                </div>

                <div class = "project_container">
                    <img src={ip_ca2} alt="sample" className="project_pic"/>
                    <div class="project_title">Cell Extractor</div>
                    <div class="project_paragraph">
                        This project utilizes contour extraction from openCV and allows the user to extract cells. 
                        User gets to decided which cell they want to extract by clicking on it. 
                        Behind the scenes, the program takes the biggest contour and extracts the rectangle area. 
                        Then the rectangle area is drawn around the click location. 
                    </div>
                </div>

                <div class = "project_container">
                    <img src={tinderbot} alt="sample" className="project_pic"/>
                    <div class="project_title">AI Tinder Bot</div>
                    <div class="project_paragraph">
                        This bot allows you to collect your own data from the website. The data will be used to classify, like or dislike, a person on tinder.
                        You can also add some images from outside of tinder. Each image is prepared (downloading, extracting features and preparing it for our CNN).
                        We are liking or disliking based on facial features.
                    </div>
                </div>

                <div class = "project_container">
                    <img src={slam} alt="sample" className="project_pic"/>
                    <div class="project_title">Point Detection for SLAM</div>
                    <div class="project_paragraph">
                        In this project I'm using ORB algorithm provide by openCV which allows me to capture
                        key points in the scene. Each current point transitions from the previous point. This will allow for 3D
                        mapping of points in a virtual environment like Pangolin.
                    </div>
                </div>

                <div class = "project_container">
                    <img src={rlearn} alt="sample" className="project_pic"/>
                    <div class="project_title">Mountain Car RN Learning</div>
                    <div class="project_paragraph">
                        In this project I'm using GYM interface to implement Reinforcement Learning. 
                        In this scenario there is a car which is trying to reach the flag. In the beginning the car doesn't know it's goal.
                        When it gets closer to it's goal, it's given reward. When it moves further out the reward get smaller. This allows the agent
                        to understand it's goal and environment.
                    </div>
                </div>

                <div class = "project_container">
                    <img src={weather } alt="sample" className="project_pic"/>
                    <div class="project_title">Current Weather</div>
                    <div class="project_paragraph">
                        In this project I'm creating a simple interface which displays current weather. I'm using pyqt5 to create the interface.
                        Input, which is name of town, is taken and makes contact with the weather API to return the current weather alongside
                        pressure, humidity and some description provided by the API.
                    </div>
                </div>
            </div>
        </div>
    )
}
